body {
  position: fixed;
  font-family: "Manrope";
}

.fullWindow {
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;

  transition: top 0.5s;
}

.subpage {
  top: -100vh;
  top: calc(var(--vh, 1vh) * -100);
}

* {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

*::-webkit-scrollbar {
  display: none;
}

.sky {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-bottom: -5vh;
  padding-bottom: calc(var(--vh, 1vh) * -5);

  background-color: #d8d8d8;
  background-image: linear-gradient(to top, #242424 13%, #d8d8d8 14%);
}

.groundScroll {
  width: 100vw;
  overflow-y: scroll;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background: #242424;
  color: white;
}

.rollingPicture {
  position: sticky;
  width: 20vmin;
  min-width: 130px;
  border-radius: 50%;
  top: 75vh;
  top: calc(var(--vh, 1vh) * 75);
  border: 1px solid black;
  z-index: 300;
}

.boxInSky {
  position: absolute;
  text-align: center;
  border-radius: 3em;
  box-shadow: inset 0 0 1em #12121296;
  background-color: #f2f2f2;
  padding: 1em;
  transition: all 0.3s;
}

.boxLink {
  color: blue;
}
.boxLink:hover .boxInSky {
  transform: scale(1.2);
}

button.boxLink {
  border: none;
  background-color: none;
  width: 0;
  height: 0;
}

.gameCanvas {
  background-color: lightblue;
}

iframe {
  box-sizing: border-box;
}

.title {
  text-align: center;
}

.link {
  color: white;
}

.link:visited {
  color: #aaa;
}

button {
  font-family: inherit;
  font-size: inherit;
}